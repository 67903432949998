import React from 'react';

import { getSrc } from 'gatsby-plugin-image';

function itemPush({ item, slideNumber, activeSlide, isMobile }) {
  var bkgImage;
  if (isMobile) {
    let imageMobile = getSrc(item.backgroundImageMobile);
    bkgImage = {
      backgroundImage: imageMobile ? 'url(' + imageMobile + ')' : '',
    };
  } else {
    let imageDesktop = getSrc(item.bkgImg);
    bkgImage = {
      backgroundImage: imageDesktop ? 'url(' + imageDesktop + ')' : '',
    };
  }

  return (
    <section className="item bkg-color3">
      <div className={'hero is-danger is-fullheight'} style={{ ...bkgImage }}>
        {item.backgroundVideo && activeSlide === slideNumber && (
          <video muted autoPlay loop className="bkg-video">
            <source src={item.backgroundVideo.file.url} type="video/mp4" />
          </video>
        )}

        <div className="hero-body is-justify-content-center has-text-centered is-uppercase">
          <div>
            {item.title && <h1 className="title is-1 mb-6">{item.title}</h1>}
            {item.description && (
              <div
                className="mb-6"
                dangerouslySetInnerHTML={{
                  __html: item.description?.childMarkdownRemark?.html,
                }}
              />
            )}
            {item.cta && (
              <a href={item.url} className="button is-transparent">
                {item.cta}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default itemPush;
