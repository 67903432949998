import React from 'react';
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import { getSrc } from 'gatsby-plugin-image';

function itemProject({ item, slideNumber, activeSlide, intl }) {
  const image = getSrc(item.MainPlaceholderVideo.visuel);

  return (
    <section className="item bkg-color3">
      <div
        className="hero is-danger is-fullheight"
        style={{
          backgroundImage: 'url(' + image + ')',
        }}
      >
        {item.MainPlaceholderVideo.backgroundVideo &&
          activeSlide === slideNumber && (
            <video muted autoPlay loop className="bkg-video">
              <source
                src={item.MainPlaceholderVideo.backgroundVideo.file.url}
                type="video/mp4"
              />
            </video>
          )}

        <div className="hero-body is-justify-content-center has-text-centered is-uppercase">
          <div>
            <p className="subtitle is-2 mb-4">{item.client.label} </p>
            <h1 className="title is-1 mb-6">{item.title}</h1>
            <div
              className="mb-6"
              dangerouslySetInnerHTML={{
                __html: item.catchphrase.childMarkdownRemark.html,
              }}
            />
            <Link
              to={`/${item.seo.slug}`}
              className="button is-transparent"
              aria-label={`${intl.formatMessage({
                id: 'see_case',
              })} ${item.title}`}
            >
              <FormattedMessage id="see_case" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default injectIntl(itemProject);
