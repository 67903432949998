import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';
import Slider from 'react-slick';
import Helmet from 'react-helmet';
import { useSwipeable } from 'react-swipeable';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ItemPush from '../components/homepage/itemPush';
import ItemProject from '../components/homepage/itemProject';

export const Swipeable = ({ children, ...props }) => {
  const slider = props.refSlider;
  const handlers = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    onSwipedUp: () => slider.slickNext(),
    onSwipedDown: () => slider.slickPrev(),
    props,
  });
  return (
    <div className={props.cssClass} {...handlers}>
      {children}
    </div>
  );
};

function CustomNextArrow() {
  return <div style={{ display: 'none' }} aria-hidden={true} tabIndex={-1} />;
}

function CustomPrevArrow() {
  return <div style={{ display: 'none' }} aria-hidden={true} tabIndex={-1} />;
}

export class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
      activeSlide2: 0,
      doAnimation: false,
      slidesLenght: 0,
      isMobile: true,
    };
  }

  componentDidMount() {
    const slickListDiv = document.getElementsByClassName('slick-list')[0];
    const posFooter = document.getElementsByClassName('footer')[0].offsetTop;

    slickListDiv.addEventListener('wheel', event => {
      event.preventDefault();
      const scrollPx = document.documentElement.scrollTop;

      // Prevent simultanate scroll
      if (!this.state.doAnimation) {
        if (event.deltaY > 0) {
          if (this.state.activeSlide2 !== this.state.slidesLenght) {
            this.slider.slickNext();
          } else {
            // If last slide, scroll to footer
            window.scrollTo({
              top: posFooter,
              behavior: 'smooth',
            });
          }
        } else if (this.state.activeSlide2 !== 0) {
          // If not the first-slide
          if (
            this.state.activeSlide2 === this.state.slidesLenght &&
            scrollPx !== 0
          ) {
            // If scroll top and last slide, "hide" footer
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            this.slider.slickPrev();
          }
        }
      }
    });

    // Keyboad navigation
    slickListDiv.addEventListener('keydown', event => {
      if (!this.state.doAnimation) {
        if (event.key === 'ArrowDown') {
          this.slider.slickNext();
        }
        if (event.key === 'ArrowUp') {
          this.slider.slickPrev();
        }
      }
    });

    this.resizeWindow();
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.resizeWindow);
  }

  // Get width window
  resizeWindow = () => {
    if (window.innerWidth <= 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const { pageContext, data, intl } = this.props;

    const slideHp = data.allContentfulHomepage.nodes[0].slides;

    const settings = {
      accessibility: true,
      arrow: false,
      dots: true,
      easing: true,
      focusOnSelect: false,
      infinite: false,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
      customPaging: function (i) {
        return (
          <button
            type="button"
            data-role="none"
            role="button"
            aria-required="false"
            tabIndex="-1"
          >
            {i + 1}
          </button>
        );
      },
      onInit: () => {
        this.setState({ slidesLenght: slideHp.length });
      },
      beforeChange: next => {
        this.setState({ activeSlide: next, doAnimation: true });
      },
      afterChange: current => {
        this.setState({ activeSlide2: current });
        setTimeout(() => {
          this.setState({ doAnimation: false });
        }, 1000);
      },
    };

    return (
      <Layout
        pageContext={pageContext}
        headerCssClass="is-fixed-top"
        mainCssClass={`bkg-color3 homepage ${
          this.state.activeSlide2 == this.state.slidesLenght
            ? 'hide-icon-scroll'
            : ''
        }`}
      >
        <SEO
          title={intl.formatMessage({ id: 'title_home' })}
          titleOnglet={intl.formatMessage({ id: 'title_home' })}
        />

        <Helmet>
          <html className="no-scroll" />
          <body className="no-scroll" />
        </Helmet>

        <Swipeable
          refSlider={this.slider}
          cssClass={`wrapper-slider ${
            this.state.doAnimation ? 'active-animate' : ''
          }`}
        >
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {slideHp?.map((item, i) => {
              switch (item.__typename) {
                case 'ContentfulPushHp':
                  return (
                    <ItemPush
                      key={i}
                      item={item}
                      slideNumber={i}
                      activeSlide={this.state.activeSlide2}
                      isMobile={this.state.isMobile}
                    />
                  );

                case 'ContentfulPageProjet':
                  return (
                    <ItemProject
                      key={i}
                      item={item}
                      slideNumber={i}
                      activeSlide={this.state.activeSlide2}
                    />
                  );

                default:
                  break;
              }
            })}
            <section className="item bkg-color2">
              <div className="hero is-danger is-fullheight">
                <div className="hero-body is-justify-content-center has-text-centered is-uppercase">
                  <div>
                    <h1 className="title is-1 pb-6 mb-6">
                      <FormattedMessage id="joinus.title" />
                    </h1>
                    <Link to="/joinus" className="button is-transparent">
                      <FormattedMessage id="joinus.cta" />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </Slider>
          <div className="icon-scroll is-hidden-mobile"></div>
          <span className="icon-arrow-down is-hidden-desktop"></span>
        </Swipeable>
      </Layout>
    );
  }
}

export const query = graphql`
  query ContentfulProjects($language: String!) {
    allContentfulHomepage(
      filter: { node_locale: { eq: $language } }
      limit: 1
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        slides {
          __typename
          ... on Node {
            ... on ContentfulPageProjet {
              id
              title
              seo {
                slug
              }
              client {
                label
              }
              catchphrase {
                childMarkdownRemark {
                  html
                }
              }
              MainPlaceholderVideo {
                visuel {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  description
                }
                backgroundVideo {
                  file {
                    url
                  }
                }
              }
            }
            ... on ContentfulPushHp {
              id
              backgroundImageMobile {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                description
              }
              bkgImg {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                description
              }
              cta
              description {
                childMarkdownRemark {
                  html
                }
              }
              title
              url
            }
          }
        }
      }
    }
  }
`;

export default injectIntl(IndexPage);
